import React from "react"
import Flex from "../../utils/flex"
import ctl from "@netlify/classnames-template-literals"
import Container from "../../utils/container"
import ContactUs from "../../animations/contact-us"
import ContactUsMobile from "../../animations/contact-us-mobile"

const ContactUsPageIntro = () => {
  return (
    <Container>
      <Flex
        alignTop
        firstItem={
          <>
            <h1 className='font-extrabold md:text-[40px] md:leading-[1.25]'>
              Contact us, get
              <br />
              the help you need
            </h1>
            <p>
              We know that trading can be a lot, and
              <br /> that’s why we’ll always be here to attend
              <br /> to your concerns.
            </p>
            <ul className={linksStyle}>
              {links.map((link, index) => {
                const { action, text, url } = link
                return (
                  <li key={index}>
                    <a href={url} className={linkStyle}>
                      <b>{action}</b> : {text}
                    </a>
                  </li>
                )
              })}
            </ul>
          </>
        }
        className='md-max:pt-[40px] mb-[60px] md:mb-[100px]'
        secondItem={
          <>
            <div className={`${imageStyle} md-max:hidden`}>
              <ContactUs />
            </div>
            <div className={`${imageStyle} md:!hidden`}>
              <ContactUsMobile />
            </div>
          </>
        }
      />
    </Container>
  )
}

const linksStyle = ctl(`md:mt-[33px] mt-[40px]`)
const linkStyle = ctl(
  `text-blue inline-block underline text-[15px] md:mb-[20px] mb-[30px]`
)
const imageStyle = ctl(`mx-auto max-w-[443px]`)

const links = [
  {
    action: "Call",
    text: "0908 290 8871",
    url: "tel:+2349082908871",
  },
  {
    action: "Email",
    text: "support@eagleglobalmarkets.com",
    url: "mailto:support@eagleglobalmarkets.com",
  },
  {
    action: "WhatsApp",
    text: "0908 290 8871",
    url: "https://wa.me/+2349082908871",
  },
]

export default ContactUsPageIntro
