import * as React from "react"
import Layout from "../components/layout"
import ContactUsPageIntro from "../components/pages/contact-us/intro"
import MediaMentions from "../components/utils/media"
import { TradingSteps } from "../components/utils/trading"
import Testimonials from "../components/pages/about-us/testimonials"

const ContactUsPage = () => (
  <Layout title="Contact us" description='For your support needs, kindly reach us via 09082908871 or send an email to support@eagleglobalmarkets.com'>
    <ContactUsPageIntro />
    <Testimonials/>
    <MediaMentions className='!mb-[100px]' />
    <TradingSteps long/>
  </Layout>
)

export default ContactUsPage
